@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "twentyRegular";   /*Can be any text*/
  src: local("TwCenClassMTStd-Regular"),
    url("./assets/fonts/twenty-font/TwCenClassMTStd-Regular.otf") format("truetype");
}

body {
  font-family: 'Rubik' !important;
}

.hh-txt {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  
  .hh-txt-2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .hh-txt-3 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
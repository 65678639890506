// b4da23
$numOfParts: 4;
$animTime: 2s;
$animTime2: 3s;
$animTime3: 4s;
$stagger: 0.08s;
$sliderReadyTrans: all $animTime/2 $animTime;
$maxLettersStagger: 6;
$letterStagger: 0.1s;

.slider {
  overflow: hidden;
  position: relative;
  height: calc(100vh - 180px);
  color: #fff;
  width: 100%;
  
  @mixin sliderReady {
    .slider.s--ready & {
      @content;
    }
  }

  &__top-heading {
    z-index: $numOfParts*3;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    transition: $sliderReadyTrans;
    transform: translateY(-30px);
    opacity: 0;
    
    @include sliderReady {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__slides {
    position: relative;
    height: 100%;
  }

  &__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.s--active {
      pointer-events: auto;
    }
    
    @mixin slidePrev {
      .slider__slide.s--prev & {
        @content;
      }
    }

    @mixin slideActive {
      .slider__slide.s--active & {
        @content;
      }
    }

    &-content {
      z-index: $numOfParts + 2;;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      text-transform: uppercase;
      line-height: 1;
    }
    
    @mixin subTextsActiveSlide {
      opacity: 0;
      transition: $animTime/2;
      
      @include slideActive {
        transition-delay: $animTime*0.65;
        opacity: 1;
        transform: translateY(0);
      }
    }

    @mixin headingsActiveSlide {
      opacity: 0;
      transition: $animTime2/2;
      
      @include slideActive {
        transition-delay: $animTime2*0.65;
        opacity: 1;
        transform: translateY(0);
      }
    }

    @mixin labelActiveSlide {
      opacity: 0;
      transition: $animTime3/2;
      transform: translateY(-100px);
      
      @include slideActive {
        transition-delay: $animTime3*0.65;
        opacity: 1;
        transform: translateY(0);
      }
    }

    

    &-subheading {
      transform: translateY(20px);
      text-transform: initial !important;
      width: 100%;

      font-weight: 500;
      font-size: 56px;
      letter-spacing: 0.2px;
      color: #161D25;
      
      @include subTextsActiveSlide;
    }



    &-image {
      transform: translateY(20px);
      @include subTextsActiveSlide;
    }

    &-heading {
      
      display: flex;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #959EAD;
      text-transform: initial !important;
      margin: 30px 0;
      @include headingsActiveSlide;
      
     
    }
    &-label {
      $fontSize: 50px;
      display: flex;
      margin-bottom: 5px;
      font-size: $fontSize;
      text-transform: uppercase;
      color: #9AC500;
      @include labelActiveSlide;
    }

    &-readmore {
      position: relative;
      font-size: 25px;
      text-transform: lowercase;
      backface-visibility: hidden;
      transform: translateY(-20px);
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 10px;
        width: 30px;
      }
      
      @include subTextsActiveSlide;
      
      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: -3px;
        width: calc(100% + 4px);
        height: calc(100% + 6px);
        transform: scaleX(0.3);
        transform-origin: 0 50%;
        transition: transform 0.3s;
      }
      
      &:hover:before {
        transform: scaleX(1);
      }
    }

    &-parts {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      
      &:after {
        content: '';
        z-index: $numOfParts + 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.1);
      }
    }

    &-part {
      $partW: (100vw / $numOfParts);
      
      position: relative;
      width: percentage(1 / $numOfParts);
      height: 100%;
      
      $partRef: &;
      $imageFadeAT: $animTime/4;

      &-inner {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 0 0;
        background-repeat: no-repeat;
        transition: transform $animTime/2 ease-in-out;

        &:before {
          content: '';
          position: absolute;
          width: 100vw;
          height: 100%;
          background-image: inherit;
          background-size: cover;
          background-position: center center;
          transition: opacity $imageFadeAT;
          opacity: 0;
        }

        @for $i from 1 through $numOfParts {
          #{$partRef}:nth-child(#{$i}) & {
            $delayOut: ($numOfParts - $i) * $stagger;
            $delayIn: $i * $stagger + $animTime/5;
            
            z-index: $numOfParts - $i;
            transition-delay: $delayOut;
            transform: translateX(percentage($i / $numOfParts * -1.3));
            
            @include slideActive {
              transition-delay: $delayIn;
            }

            &:before {
              left: $partW * ($i - 1) * -1;
              transition-delay: $delayOut + $imageFadeAT/2;
              
              @include slideActive {
                transition-delay: $delayIn;
              }
            }
          }
        }
        
        @include slideActive {
          transform: translateX(0);
          transition-timing-function: ease;
          
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
  
  &__control {
    $size: 64px;
    
    z-index: 100;
    position: absolute;
    left: 50px;
    top: 50%;
    width: $size;
    height: $size;
    margin-top: $size/-2;
    border-radius: 50%;
    background: rgba(255,255,255,1);
    transform: translateX($size*-1);
    opacity: 0;
    transition: $sliderReadyTrans;
    box-shadow: 0px 28px 32px rgba(0, 0, 0, 0.03);
    cursor: pointer;
    
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      margin-left: -2px;
      margin-top: -2px;
      border: 2px solid #000;
      border-bottom: none;
      border-right: none;
      transform: translateX(5px) rotate(-45deg);
    }
    
    &--right {
      left: auto;
      right: 0px;
      transform: translateX($size);
      
      &:before {
        transform: translateX(-5px) rotate(135deg);
      }
    }
    &--left {
      display: none !important;
    }
    
    @include sliderReady {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.icon-link {
  z-index: 100;
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;

  img {
    width: 100%;
    vertical-align: top;
  }

  &--twitter {
    left: auto;
    right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .slider {
    overflow: hidden;
    position: relative;
    height: 350px;
    color: #fff;
    border-bottom: 1px solid #000;
    
    @mixin sliderReady {
      .slider.s--ready & {
        @content;
      }
    }
  
    &__top-heading {
      z-index: $numOfParts*3;
      position: absolute;
      left: 0;
      top: 100px;
      width: 100%;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      transition: $sliderReadyTrans;
      transform: translateY(-30px);
      opacity: 0;
      
      @include sliderReady {
        transform: translateY(0);
        opacity: 1;
      }
    }
  
    &__slides {
      position: relative;
      height: 100%;
      width: 100%;
    }
  
    &__slide {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
  
      &.s--active {
        pointer-events: auto;
      }
      
      @mixin slidePrev {
        .slider__slide.s--prev & {
          @content;
        }
      }
  
      @mixin slideActive {
        .slider__slide.s--active & {
          @content;
        }
      }
  
      &-content {
        z-index: $numOfParts + 2;;
        position: absolute;
        left: 25px;
        bottom: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row !important;
        height: auto;
        text-transform: uppercase;
        line-height: 1;
      }
      
      @mixin subTextsActiveSlide {
        opacity: 0;
        transition: $animTime/2;
        
        @include slideActive {
          transition-delay: $animTime*0.65;
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      &-subheading {
        margin-bottom: 0px !important;
        font-size: 16px;
        letter-spacing: 2px;
        transform: translateY(20px);
        float: left;
        @include subTextsActiveSlide;
      }



      &-image {
        transform: translateY(20px);
        @include subTextsActiveSlide;
      }
  
      &-heading {
        $fontSize: 16px;
        font-size: $fontSize;
        margin-left: 5px;
        font-weight: 400;
        line-height: 28px;
        color: #959EAD;
        text-transform: initial !important;
        margin: 20px 0;
      }

      &-readmore {
        position: absolute;
        bottom: -25px;
        left: 15px;
        font-size: 13px;
        text-transform: lowercase;
        backface-visibility: hidden;
        transform: translateY(-20px);
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
  
        img {
          margin-right: 10px;
          width: 15px;
        }
        
        
      }
    }
    
    &__control {
      $size: 20px;
      display: none;
      z-index: 100;
      position: absolute;
      left: 20px;
      top: 50%;
      width: $size;
      height: $size;
      margin-top: $size/-2;
      border-radius: 50%;
      background: rgba(255,255,255,0.4);
      transform: translateX($size*-1);
      opacity: 0;
      transition: $sliderReadyTrans;
      cursor: pointer;
      
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        margin-left: -4px;
        margin-top: -5px;
        border: 2px solid #000;
        border-bottom: none;
        border-right: none;
        transform: translateX(5px) rotate(-45deg);
      }
      
      &--right {
        left: auto;
        right: 0px;
        transform: translateX($size);
        
        &:before {
          transform: translateX(-5px) rotate(135deg);
        }
      }
      
      @include sliderReady {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {

}
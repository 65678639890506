.main-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;

    .main-nav {
        z-index: 2000;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .w-max-width {
        max-width: 1440px !important;
    }

    .marquee-header {
        height: 30px;
        background: #489311;
        font-size: 11px;
        font-weight: bold;
        color: #ffffff;
    }

    .main-header {
        width: 100%;
        background: #fff;
        
        padding: 25px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .main-header-menu {
            margin-top: 20px;
            overflow: hidden;
            .main-menu-item {
                padding: 0 15px;
                font-size: 14px;
                color: #489311;
                letter-spacing: 1px;
                transition: all .2s linear;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1;
                &:hover {
                    cursor: pointer;
                    transition: all .3s ease;
                    transform: scale(1.2);
                }
            }
        }
        
    }

    .main-footer-menu {
        .main-menu-item {
            padding: 0 15px;
            color: #FFFFFF;
        }

        
    }

    .ft-item-text {
        font-size: 13px;
    }

    .product-gap {
        width: 100%;
        height: 600px;

        .text-inner {
            &:hover {
                background-color: rgba(0, 0, 0, .7); 
            }
        }
        &:hover {
            -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 20px black;
        }
        cursor: pointer;
    }
        
    .search-button {
        background-color: #E73A5D;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .header-search-field {
        display: flex;
        justify-content: center;
        background-color: #F9FAFB;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        height: 44px;
        padding: 0 15px;
        input {
            background-color: transparent;
            border: none;
            border-right: 1px solid #F4F6F8;
            width: 245px;
            &:focus {
                outline: none !important;
                border: none !important;
            }
        }
    }

    .tools {
        margin-left: 22px;
    }

    .user-account {
        .user-name {
            font-size: 16px;
            letter-spacing: 0.177778px;
            color: #637381;
            cursor: pointer;
        }

        .user-login {
            font-size: 16px;
            letter-spacing: 0.177778px;
            color: #637381;
            cursor: pointer;

            span {
                margin-right: 10px;
                color: #161D25;
            }


            .login-options {
                a {
                    color: #637381;
                    text-decoration: none;
                    font-size: 16px;
                    letter-spacing: 0.177778px;
                    border-bottom: 0.5px solid #63738117;
                    padding: 6px 0;
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .main-banner {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #959EAD;
            text-transform: initial !important;

            margin: 20px 0;
        }
    }
    .full-button {
        padding: 13px 30px;
    }

    .features {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;

        .feature-item {
            .infos {
                margin-left: 20px;
                .label {
                    font-weight: 500;
                    font-size: 18px;
                    letter-spacing: 0.2px;
                    color: #161D25;
                }

                .descirption {
                    font-weight: 400;
                    font-size: 16px;
                    color: #5A7184;
                }
            }
        }
    }

    .best-products {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .header {

            .title {
                font-weight: 500;
                font-size: 36px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #161D25;
                text-transform: uppercase;
                position: relative;
                margin: 30px auto 50px auto;
                display: flex;
                justify-content: center;
                &:before {
                    content: "";
                    display: block;
                    width: 30%;
                    height: 2px;
                    background: #161D25;
                    left: 0;
                    top: 50%;
                    position: absolute;
                  }
                  &:after {
                    content: "";
                    display: block;
                    width: 30%;
                    height: 2px;
                    background: #161D25;
                    right: 0;
                    top: 50%;
                    position: absolute;
                  }
            }

        }
    }

    .list-products {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .header {

            .title {
                font-weight: 500;
                font-size: 26px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #161D25;
                text-transform: uppercase;
                position: relative;
                margin: 50px auto;
                display: flex;
                justify-content: center;
                &:before {
                    content: "";
                    display: block;
                    width: 30%;
                    height: 2px;
                    background: #161D25;
                    left: 0;
                    top: 50%;
                    position: absolute;
                  }
                  &:after {
                    content: "";
                    display: block;
                    width: 30%;
                    height: 2px;
                    background: #161D25;
                    right: 0;
                    top: 50%;
                    position: absolute;
                  }
            }

        }
    }

    .products-list {
        margin-top: 30px;
    }

    .main-product-card {
        width: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: end;
        cursor: pointer;

        .product-hover-options {
            display: none;
        }

        &-content {
            .product-image {
                display: flex;
                justify-content: center;

                img {
                    border-radius: 20px;
                }
            }
    
            .product-infos {
                margin-top: 26px;
                &-subcategory {
                    font-weight: 400;
                    font-size: 12px;
                    color: #959EAD;
                }
    
                &-title {
                    font-weight: 500;
                    font-size: 16px;
                    color: #161D25;
                    margin: 10px 0;
                }
    
                &-price {
                    font-weight: 500;
                    font-size: 14px;
                    color: #5C6AC4;
                }
            }
        }

        
        
    }

    .home-product-banner {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px 0px 0px 0px;

        &-content {
            width: 100%;
            height: 250px;

            .banner-details {
                .banner-version {
                    font-weight: 500;
                    font-size: 14px;
                    color: #47C1BF;
                    margin-bottom: 25px;
                }

                .banner-product-title {
                    font-weight: 500;
                    font-size: 36px;
                    letter-spacing: 0.2px;
                    color: #161D25;
                }

                .banner-product-small-description {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #959EAD;
                    margin-bottom: 25px;
                }
            }
        }
        
    }

    .home-half-product {
        width: 100%;
        height: 350px;
    }

    .home-category {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;

        &-content {
            padding: 40px 0;
            border-top: 1px solid #F4F6F8;

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title {
                    font-weight: 500;
                    font-size: 28px;
                    letter-spacing: 0.2px;
                    color: #161D25;
                }
            }

            .home-category-list {
                padding: 40px 0;
            }

            .details {
                margin-top: 30px;

                .title {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #161D25;

                }

                .count {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #959EAD;
                }
            }
        }
    }

    .product-list {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;

        &-content {
            padding: 40px 0;
            border-bottom: 1px solid #F4F6F8;
            .product-list-item {
                &-title {
                    font-weight: 500;
                    font-size: 22px;
                    letter-spacing: 0.2px;
                    color: #161D25;
                }

                .view-more {
                    span {
                        font-weight: 500;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        color: #F4A607;

                    }
                }
            }
        }
        
    }

    .small-product-card {
        width: 100%;
        .small-product-image { 
            img {

                border-radius: 0 !important;
            }
        }

        .small-product-infos {
            margin-top: 20px;
            .small-product-title {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #161D25;
            }
            .product-infos-subcategory {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #B2B2B2;

                &-sold-out {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #ef0000;
                }
            }


            .product-infos-price {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #489311;
            }

        }


        .add-to-card {
            display: flex;
            background-color: #489311;
            color: #FFFFFF;
            text-transform: uppercase;
            padding: 2px 8px;
            margin-top: 5px;
            font-size: 14px;
        }
    }

    .blog-list {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px 0;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
               p {
                font-weight: 500;
                font-size: 36px;
                letter-spacing: 0.2px;
                color: #161D25;
               }

               span {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #959EAD;
               }
            }
        }
    }

    .blog-card {
        width: 350px;

        .blog-card-image {
            width: 100%;
            height: 200px;
            border-radius: 8px;
        }

        .blog-card-infos {
            .date {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #5C6AC4;
                margin-top: 30px;
            }

            .title {
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 0.2px;
                color: #161D25;
                margin: 15px 0;
            }

            .description {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #959EAD;
                
            }
        }
    }

    .partner-list {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;

        &-content {
            padding: 40px 0;
        }
    }

    .main-footer {
        width: 100%;
        background-color: #000000;
        display: flex;
        align-items: center;
        flex-direction: column;

        padding: 50px 0;

        .footer-text {
            margin-top: 25px;
            font-weight: 400;
            font-size: 14px;
            color: #959EAD;
        }

        .footer-widget {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            padding: 0 5px;
            .fw-title {
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.177778px;
                color: #161D25;
                margin-bottom: 20px;
            }

            li {
                margin-bottom: 15px;
            }

            .fw-link, .footer-contact {
                ul {
                    padding-left: 0 !important;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                }

                a {
                    text-decoration: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #161D25;
                    text-align: end;
                }
            }

            .footer-contact {
                li {
                    text-align: end;
                }
            }
        }

       
    }

    .category-page {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .category-page {
        width: 100%;
        position: relative;
    }

    .category-header {
        width: 100%;
        height: 300px;
    }

    .category-w-image {
        width: 350px;
        padding: 20px;

        background: #FFFFFF;
        box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin-bottom: 30px;

        .image-feature {
            height: 180px;
            width: 100%;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0;
        }

        .product-title {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;

            .label {
                font-weight: 500;
                font-size: 22px;
                letter-spacing: 0.2px;
                color: #161D25;
                
            }
            .count {
                font-weight: 400;
                font-size: 14px;
            }
        }
        .sub-items {
            .sub-item {
                display: flex;
                justify-content: space-between;
                margin: 10px 0;

                .label {
                    display: flex;
                    font-weight: 400;
                    font-size: 16px;
                    color: #161D25;
                }

                .count {
                    font-weight: 400;
                    font-size: 14px;
                    color: #959EAD;
                }
            }
        }
    }

    .product-full-list {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: column;

        .filters {
            .label {
                font-weight: 500;
                font-size: 20px;
                letter-spacing: 0.2px;
                color: #161D25;
            }

            .popular-filter {
                border: 1px solid #F4F6F8;
                border-radius: 8px;
                margin-bottom: 20px;
            }

            .price-filter {
                width: 100%;
                height: 42px;
                border: 1px solid #F4F6F8;
                border-radius: 8px;
                display: flex;

                .icon {
                    height: 100%;
                    width: 52px;
                    background: #F4F6F8;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                input {
                    margin-left: 15px;
                    border: none;
                    height: 42px;
                    width: 100%;
                    &:focus {
                        outline: none !important;
                    }
                }
            }
        }

        .right-side {
            padding-left: 30px;
            .intro {
                padding-bottom: 25px;
                border-bottom: 1px solid #F4F6F8;
                p {
                    font-weight: 400;
                    font-size: 16px;
                    color: #5A7184;
                }

                .sort {
                    display: flex;
                    align-items: center;
                    .label {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        color: #959EAD;
                    }

                    .sort-area {
                        background: #F9FAFB;
                        border-radius: 8px;
                        height: 52px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        select {
                            background-color: transparent !important;
                            &:focus {
                                outline: none !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-banner {
        padding: 25px 0;
        border-bottom: 1px solid #F4F6F8;

        &-label {
            p {
                font-weight: 500;
                font-size: 32px;
                letter-spacing: 0.2px;
                color: #161D25;
            }
        }

        .toogle {
            border: 1px solid #F4F6F8;
            border-radius: 8px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-item {
                padding: 10px;
                border-radius: 8px;
                &-active {
                    background-color: #454F5B;
                }
            }
        }
    }

    .second-product-card {
        height: 380px;
        width: 255px;
        background-color: #FFFFFF;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        cursor: pointer;

        .product-hover-options {
            display: none;
        }

        &-content {
            padding: 25px 15px;
            .product-image {
                display: flex;
                justify-content: center;
            }
    
            .product-infos {
                margin-top: 26px;
                &-subcategory {
                    font-weight: 400;
                    font-size: 12px;
                    color: #959EAD;
                }
    
                &-title {
                    font-weight: 500;
                    font-size: 18px;
                    color: #161D25;
                    margin: 10px 0;
                }
    
                &-price {
                    font-weight: 500;
                    font-size: 16px;
                    color: #50B83C;
                }
    
                &-rate {
                    display: flex;
                }
            }
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 24px 0px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            z-index: 1000;
            .product-hover-options {
                padding: 25px 15px;
                display: block;
                position: absolute;
                top: 360px;
                background-color: #FFFFFF;
                width: 100%;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px;
            }
        }
    }

    .details-page{
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        .top-side {
            .details-image-side {
                width: 418px;

                .details-big-image {
                    border: 1px solid #E5E5E5;
                    padding: 30px;
                }


                .image-thumbs-item {
                    border: 1px solid #E5E5E5;
                    padding: 10px;
                }

                
            }

            .infos-description-side {
                margin-right: 50px;
                margin-left: 50px;
                width: 700px;
                

                .product-details-rate {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    .rate {
                        background: #F49342;
                        border-radius: 8px;
                        padding: 2px 10px;
                        color: #fff;
                    }

                    .sold-count, .view-count {
                        font-weight: 500;
                        font-size: 14px;
                        color: #959EAD;

                    }
                }
                

                

                .product-details-price-side {
                    padding: 25px 0;
                    

                    .heart {
                        height: 48px;
                        padding: 0 15px;
                        display: flex;
                        align-items: center;
                        border: 1px solid #F4F6F8;
                        border-radius: 8px;
                    }
                }
            }

            
        }

        .tab-section {
            border-top: 2px solid #F4F6F8;
        }
        .tab-side {
            padding: 25px 0;
            
            .tab-item {
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                color: #030303;
                cursor: pointer;

                &:hover {
                    color: #161D25;
                }
            }
        }

        .options-side {
            padding: 25px 0;

            .options-item {
                font-weight: 400;
                font-size: 16px;
                text-align: center;
                color: #959EAD;
                cursor: pointer;

                &:hover {
                    color: #161D25;
                }
            }
        }

        .full-description {
            p {
                font-weight: 400;
                font-size: 14px;
                color: #5A7184;
            }
        }
    }

    .product-details-options {
                    
        .quantity {
            display: flex;
            .minus, .plus {
                padding: 0px 20px;
                background: #000000;
                height: 32px;
                color: #ffffff;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            input {
                &:focus {outline: none;}
                background: transparent;
                border-radius: 4px;
                padding: 5px 10px;
                height: 32px;
                width: 32px;
                text-align: #000000;
            }
        }

        .colors {

            .label {
                font-weight: 500;
                font-size: 14px;
                color: #5A7184;

                margin-bottom: 15px;
            }

            &-items {
                height: 42px;
                display: flex;
                align-items: center;
                border: 1px solid #F4F6F8;
                border-radius: 8px;
                padding: 5px;

                .color-item {
                    padding: 0 15px;
                    color: #959EAD;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    font-weight: 500;
                    font-size: 16px;
                }

                .active{
                    padding: 0 15px;
                    color:#FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #637381;
                    border-radius: 8px;
                    height: 32px;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }

    .order-details-options {
        .header-2 {
            margin-top: 15px;
            color: #000000;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }

        .title {
            margin-top: 5px;
            color: #7E7D7D;
            font-size: 18px;
            text-align: center;
            font-weight: bold;
        }

        .select-form {
            width: 100%;
            padding: 0 25px;

            select {
                width: 100%;
                padding: 10px 5px;
                margin-top: 20px;
                border: 1px solid #CBCBCB;
                border-radius: 5px;
                &:focus {
                    outline: none;
                }
            }

            input {
                width: 100%;
                padding: 10px 5px;
                margin-top: 20px;
                border: 1px solid #CBCBCB;
                border-radius: 5px;
                &:focus {
                    outline: none;
                }
            }
        }
    }

    .product-details-title {
        font-weight: 500;
        font-size: 26px;
        letter-spacing: 0.2px;
        color: #161D25;
    }
    .details-small-decription {
        font-weight: 400;
        font-size: 16px;
        color: #5A7184;
    }

    .current-price {
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 0.2px;
        color: #489311;
        text-transform: uppercase;
    }

    .old-price {
        color: #ffa803;
        text-transform: uppercase;
        text-decoration: line-through;
    }

    .cart-price {
        color: #454F5B;
        text-transform: uppercase;
    }

    .cart-page {
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        .top-side {
            .header {
                padding-bottom: 15px;
                border-bottom: 1px solid #CBCBCB;
                font-size: 18px;
                font-weight: bold;
            }
            .order-cart-options {
                width: 413px;
                min-height: 430px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 29px;

                .header {
                    color: #000000;
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                    padding: 15px 0;
                }

               .sub-total {
                    padding: 15px 40px;
                    border-bottom: 1px solid #CBCBCB;

                    .title {
                        letter-spacing: -0.56px;
                    }

                    .value {
                        font-weight: 700;
                        font-size: 22px;
                        letter-spacing: 0.2px;
                        color: #489311;
                        text-transform: uppercase;
                    }
               }
            }
        }

        .options-side {
            padding: 25px 0;

            .options-item {
                font-weight: 400;
                font-size: 16px;
                text-align: center;
                color: #959EAD;
                cursor: pointer;

                &:hover {
                    color: #161D25;
                }
            }
        }

        .image-side {
            border: 1px solid #E5E5E5;
            padding: 15px;
        }

    }

    .cart-item-card {
        border-bottom: 1px solid #CBCBCB;
        padding-bottom: 15px;
        &:last-child {
            border-bottom: 0px;
        }
    }

    
    
}

.sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
  }


.modal-content {
    background-color: transparent !important;
    border: none !important;
}

.loging-side {
    .forms {
        width: 100%;
        margin-top: 15px;
        input {
            width: 100%;
            background-color: #FFFFFF;
            border-radius: 5px;
            padding: 10px;
            height: 48px;
            margin-bottom: 15px;
    
            &:focus {
                outline: none;
                border: none;
            }
        }
    }
    
    .loging-title, .loging-sub-title {
        color: #FFFFFF;
        margin-top: 15px;
    }

    .loging-title {
        font-size: 18px;
        font-weight: bold;
    }
    .loging-sub-title  {
        font-size: 15px;
        text-align: center;
        font-style: oblique;
    }
}



@media screen and (max-width: 768px) {

    .main-container {

        .category-header {
            width: 100%;
            height: 150px;
        }
        .w-max-width {
            max-width: 100% !important;
            padding-left: 0px;
            padding-right: 0px;
        }

        .main-product-card {
            width: 100%;
            
            padding: 0 8px;
            
        }

        .home-product-banner {
            width: 100%;
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 40px 0px 0px 0px;
    
            &-content {
                width: 100%;
                height: 100px;
    
            }
            
        }
        .home-half-product {
            width: 100%;
            height: 200px;
        }

        .details-page{
            
            .top-side {
                .details-image-side {
                    width: 100%;
    
                    .details-big-image {
                        padding: 0px;
                    }
    
                   
                    .image-thumbs-item {
                        padding: 10px;
                    }
    
                    
                }
    
                .infos-description-side {
                    margin-top: 25px;
                    margin-right: 0px;
                    margin-left: 0px;
                    width: 100%;
                    
    
                    .product-details-rate {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        .rate {
                            background: #F49342;
                            border-radius: 8px;
                            padding: 2px 10px;
                            color: #fff;
                        }
    
                        .sold-count, .view-count {
                            font-weight: 500;
                            font-size: 14px;
                            color: #959EAD;
    
                        }
                    }
                    
    
                    
    
                    .product-details-price-side {
                        padding: 25px 0;
                        
    
                        .heart {
                            height: 48px;
                            padding: 0 15px;
                            display: flex;
                            align-items: center;
                            border: 1px solid #F4F6F8;
                            border-radius: 8px;
                        }
                    }
                }
    
                
            }
    
            .tab-section {
                border-top: 2px solid #F4F6F8;
            }
            .tab-side {
                padding: 25px 0;
                
                .tab-item {
                    font-weight: 700;
                    font-size: 18px;
                    text-align: center;
                    color: #030303;
                    cursor: pointer;
    
                    &:hover {
                        color: #161D25;
                    }
                }
            }
    
            .options-side {
                padding: 25px 0;
    
                .options-item {
                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;
                    color: #959EAD;
                    cursor: pointer;
    
                    &:hover {
                        color: #161D25;
                    }
                }
            }
    
            .full-description {
                p {
                    font-weight: 400;
                    font-size: 14px;
                    color: #5A7184;
                }
            }
        }

        .product-details-title {
            font-weight: 500;
            font-size: 18px;
            letter-spacing: 0.2px;
            color: #161D25;
        }

        .current-price {
            font-weight: 700;
            font-size: 18px;
            letter-spacing: 0.2px;
            color: #489311;
            text-transform: uppercase;
        }

        .cart-page {
            
            .top-side {
               
                .order-cart-options {
                    width: 100%;
                    height: auto;
                    box-shadow: none;
                    border-radius: 0;
    
                    .header {
                        color: #000000;
                        font-size: 22px;
                        font-weight: bold;
                        text-align: center;
                        padding: 15px 0;
                    }
    
                   .sub-total {
                        padding: 15px 40px;
                        border-bottom: 1px solid #CBCBCB;
    
                        .title {
                            letter-spacing: -0.56px;
                        }
    
                        .value {
                            font-weight: 700;
                            font-size: 22px;
                            letter-spacing: 0.2px;
                            color: #489311;
                            text-transform: uppercase;
                        }
                   }
                }
            }
    
            .options-side {
                padding: 25px 0;
    
                .options-item {
                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;
                    color: #959EAD;
                    cursor: pointer;
    
                    &:hover {
                        color: #161D25;
                    }
                }
            }
    
            .image-side {
                border: 1px solid #E5E5E5;
                padding: 15px;
            }
    
        }
    
        
    }
     
}
@media screen and (max-width: 1024px) {

    .main-container {
        
        .small-product-card {
    
            .add-to-card {
                font-size: 12px;
            }
        }

        .list-products, .best-products {
    
            .header {
                display: flex;
                justify-content: center;
                .title {
                    display:inline-block;
                    font-size: 20px;
                    border-bottom:2px solid #161D25;
                    padding-bottom:2px;
                    &:before {
                        width: 20%;
                        display: none !important;
                      }
                      &:after {
                        width: 20%;
                        display: none !important;
                      }
                }
    
            }
        }
    
        
    }
     
}

